import React from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import { PageContext } from 'src/types'
import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import PageContainer from 'src/components/PageContainer/PageContainer'

const NotFoundPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()

  return (
    <Layout pageContext={pageContext}>
      <Head title="404" pageContext={pageContext} />
      <PageContainer>
        <h1>{'404'}</h1>
        <p>{t('Not found')}</p>
      </PageContainer>
    </Layout>
  )
}

export default NotFoundPage
